'use client'

import React, { useState } from 'react'

const Card: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
  <div
    {...props}
    style={{
      width: '100%',
      maxWidth: '32rem',
      backgroundColor: '#1F2937',
      borderRadius: '0.75rem',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    }}
  >
    {children}
  </div>
);

const CardContent: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
  <div {...props} style={{ padding: '2rem' }}>
    {children}
  </div>
);

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => (
  <button
    {...props}
    style={{
      width: '100%',
      backgroundColor: '#3B82F6',
      color: 'white',
      padding: '0.75rem 1rem',
      borderRadius: '0.5rem',
      border: 'none',
      cursor: 'pointer',
      fontSize: '1rem',
      fontWeight: 600,
      transition: 'background-color 0.2s',
      textAlign: 'center',
    }}
  >
    {children}
  </button>
);

const formatContent = (content: string) => {
  return content
    .replace(/\n\n/g, '<br><br>')
    .replace(/•\s/g, '<span style="display: inline-block; width: 1em; margin-left: -1em;">&bull;</span> ')
    .replace(/(\d+\.)\s/g, '<span style="display: inline-block; width: 1.5em; margin-left: -1.5em;">$1</span> ')
    .replace(/^(.*):$/gm, '<strong>$1:</strong>')
    .trim();
};

const steps = [
  {
    content: "Information is wealth. Are you ready to learn a new concept?",
    button: "Yes, I'm ready!"
  },
  {
    content: `You will come across the following terms:
    <br><br>
    • Policy Holder: The individual who owns the policy.<br>
    • Policy Surrender: Closing the policy due to a lack of interest or insufficient funds to continue.<br>
    • Policy Transfer: Transferring the policy ownership from one person to another through the insurance company.`,
    button: 'Got it'
},
  {
    content: "This year, insurance policies worth 1.98 lakh crore have been surrendered by policyholders, and this number is consistently high every year, Now, imagine yourself as one of the policyholders",
    
    button: "Yes, I'm a policyholder for a minute"
  },
  {
    content: `When you surrender a policy prematurely:
<br><br>
• You get less than the actual value of your investment value<br>
• You receive the amount after the policy term ends, not immediately<br>
• You lose the associated insurance coverage
<br><br>
But is surrender the only option?`,
    button: "Are there alternatives?"
  },
  {
    content: `Yes! Many people don't know this, but Section 38 of the Insurance Act allows policy transfers. Benefits:
<br><br>
• The new policyholder pays you the surrender amount instantly<br>
• You retain the insurance coverage<br>
• If something happens to you, your family still gets the insurance amount`,
    button: "That's useful!"
  },
  {
    content: `By transferring instead of surrendering, you get:
<br><br>
1. Instant Liquidation<br>
2. Continued Insurance Benefits
<br><br>
These benefits aren't available with regular policy surrenders.`,
    button: "I understand"
  },
  {
    content: "Now, let's switch perspectives. How can you benefit from this as an investor?",
    button: "I'm Switching to Investor Mindset"
  },
  {
    content: `As an investor, it's simple:
<br><br>
• The surrender value is much less than the actual insurance value<br>
• Pay the surrender value to the original policyholder<br>
• Wait until the policy matures<br>
• Profit from the difference`,
    button: "Is this process legal?"
  },
  {
    content: `It's safe and legal:
<br><br>
• The insurance company handles all formalities<br>
• After basic KYC, they transfer the policy to you<br>
• You receive policy documents at your address<br>
• You can track everything on the insurance company's website`,
    button: "Sounds secure"
  },
  {
    content: "Now, let's talk about the most exciting part: Returns!",
    button: "I'm excited!"
  },
  {
    content: `Here's where we come into the picture:
    <br><br>
    Out of thousands of policies, we carefully select those with high returns and shorter tenure periods, working directly with insurance companies and intermediaries.<br><br>
    Before you invest, you'll know exactly how much you'll receive at maturity, as we display the returns in XIRR format for full transparency.`,
    button: "That's impressive"
},
  {
    content: `Our process:
<br><br>
• We shortlist policies ready for transfer and near maturity<br>
• We transfer the policy to your name<br>
• You wait for maturity and enjoy high returns`,
    button: "How do I start?"
  },
  {
    content: `It's simple:
<br><br>
• WhatsApp at 8801648801<br>
• We create a new whatsapp group to inform you about new deals<br>
• We'll handle all documentation<br>
• You just wait for the policy to be issued in your name`,
    button: "Whats in it for you, How you make money!"
  },
  {
    content: `Its interesting part, The insurance companies will pay us a fee for every policy transfer, Here's how they get benefit from this structure :
<br><br>
• They continue to receive premiums without handling a surrender<br>
• The policy stays active, which is better for their business model<br>
• They avoid the hassle of managing multiple surrenders`,
    button: "25+ Deals are live now, whatsapp us(8801648801), First come first serve"
  }
]

export default function ImprovedInsuranceChatbot() {
  const [currentStep, setCurrentStep] = useState(0)

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: 'black',
      padding: '1rem',
      color: 'white',
      fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      lineHeight: '1.5',
    }}>
      <h1 style={{
        fontSize: '1rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        textAlign: 'center',
        maxWidth: '80%',
        lineHeight: '1.2',
      }}>
        Short-Term Investments with High Returns, Zero Risk, and Tax-Free Benefits
      </h1>
      <Card>
        <CardContent>
          <div>
            <div style={{
              backgroundColor: '#374151',
              borderRadius: '0.5rem',
              padding: '1.5rem',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
              marginBottom: '1.5rem',
              textAlign: 'left', // Ensure left alignment
            }}>
              <div
                style={{
                  color: '#D1D5DB',
                  fontSize: '1rem',
                  lineHeight: '1.6',
                }}
                dangerouslySetInnerHTML={{ __html: formatContent(steps[currentStep].content) }}
              />
            </div>
            <Button
              onClick={handleNext}
            >
              {steps[currentStep].button}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}





  

